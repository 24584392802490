import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Projects from '../components/projects'

const ProjectsPage = ({ data }) => (
  <Layout>
    <Helmet title="Our Projects" />
    <section className="container">
      <h1 className="text-center">We take pool renovation seriously</h1>
      <h2 className="text-center">Have a look for yourself</h2>
      <Projects items={data.allMarkdownRemark.edges} />
    </section>
  </Layout>
)

export default ProjectsPage

/* eslint no-undef: 'off' */
export const pageQuery = graphql`
  query ProjectsQuery {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "/projects/" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            image
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
